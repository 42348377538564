export const NextIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="m6 18-1.417-1.417L11.167 10 4.583 3.417 6 2l8 8Z"
        fill="var(--primary-bold)"
      />
    </svg>
  );
};

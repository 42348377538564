import styled from "styled-components";

export const isDesktop = window.matchMedia("(min-width: 870px)").matches;

export const Title = styled.h1`
  color: #fdf7fa;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 26px;
  text-align: left;
`;

export const HandTitle = styled.h1`
  color: var(--title);
  font-size: 23.4px;
  text-align: center;
  font-weight: 700;
  stroke: 0.75px;
  margin: 0 0 0px 0;
  font-family: "Delicate1", sans-serif;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 14px;
  color: #72767b;
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: row;

  li {
    margin: 0 20px 0 0;
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: var(--grey);
  }

  a:hover,
  .selected {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const Nav = styled.nav`
  display: none;

  /* DESKTOP MODE */
  @media screen and (min-width: 870px) {
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 1.5em;
    height: 73px;

    li {
      width: 90px;
      margin: 0;

      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      text-decoration: none;
      color: var(--grey);
    }

    a:hover,
    .selected {
      color: var(--primary);
      font-weight: bold;
    }

    .spreaded-links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
    }
  }
`;

export const MobileNav = styled.nav`
  position: relative;

  > a {
    z-index: 1;
    height: 24px;
    margin: 0 10px 0 0;
  }

  svg {
    height: fit-content;
  }

  .menu-icon {
    * {
      height: 24px;
    }
  }

  /* DESKTOP MODE */
  @media screen and (min-width: 870px) {
    display: none;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    height: 85px;
  }

  /* DESKTOP MODE */
  @media screen and (min-width: 870px) {
    display: none;
  }
`;

export const HamburgerMenu = styled.div`
  background-color: var(--white-creme);
  padding: 40px;
  position: absolute;
  right: 0;
  z-index: 1;
  border-radius: 12px 0 0 12px;
  border: 1px solid var(--primary);

  nav {
    font-size: 14px;

    ul {
      flex-direction: column;
      padding: 0;
    }

    li {
      line-height: 30px;
      text-transform: uppercase;
    }
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  animation: slideFromRight 0.5s;
  animation-timing-function: ease-in-out;

  @keyframes slideFromRight {
    0% {
      right: -70vw;
    }
    100% {
      right: 0;
    }
  }
`;

export const HamburgerWrapper = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const FancyLine = styled.hr`
  border: 0;
  height: 1px;
  position: relative;
  margin: 0.5em 0;

  :before {
    top: -0.5em;
    height: 1em;
  }
  :after {
    height: 0.5em;
    top: calc(-0.5em + 1px);
  }

  :before,
  :after {
    content: "";
    position: absolute;
    width: 100%;
  }

  :before {
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 75%
    );
  }

  :after {
    background: #f4f4f4;
  }
`;

export const Wrapper = styled.div`
  padding: 10px 20px;
`;

export const Socials = styled.div`
  padding: 10px;

  svg {
    height: 25px;
    width: 25px;
  }

  a:hover {
    svg > path {
      fill: var(--primary);
    }
  }

  > :first-child {
    margin-right: 6.5px;
  }

  > :nth-child(2) {
    margin-right: 9px;
  }

  /* DESKTOP MODE */
  @media screen and (min-width: 870px) {
    padding: 10px 0 0 0;
  }
`;

export const WrapperApp = styled.div`
  max-width: var(--screen-size);
  width: 100%;
  height: max-content;
`;

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

import { Link } from "react-router-dom";
import { CloseIcon, MenuIcon } from "../../assets/svg";
import { headerLogo, mobileNav, nav } from "../../utils/navigation";
import {
  HamburgerMenu,
  HamburgerWrapper,
  HeaderLogo,
  MobileNav,
  Nav,
  Ul,
} from "../../utils/styles/GlobalStyles";
import { IconActionButton } from "../Buttons";

interface HeaderProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export const Header = ({ selected, setSelected }: HeaderProps) => {
  return (
    <header className="App-header">
      <Nav>
        <div className="spreaded-links">
          <li onClick={() => setSelected(nav.home.path)}>
            {
              <Link
                to={nav.home.path}
                className={selected === nav.home.path ? "selected" : ""}
              >
                {nav.home.title}
              </Link>
            }
          </li>
          <li onClick={() => setSelected(nav.yoga.path)}>
            {
              <Link
                to={nav.yoga.path}
                className={selected === nav.yoga.path ? "selected" : ""}
              >
                {nav.yoga.title}
              </Link>
            }
          </li>
          <li onClick={() => setSelected(nav.events.path)}>
            {
              <Link
                to={nav.events.path}
                className={selected === nav.events.path ? "selected" : ""}
              >
                {nav.events.title}
              </Link>
            }
          </li>
        </div>

        <div>
          {
            <Link
              to={nav.logo.path}
              className={selected === nav.logo.path ? "selected" : ""}
            >
              {nav.logo.title}
            </Link>
          }
        </div>

        <div className="spreaded-links">
          <li onClick={() => setSelected(nav.shop.path)}>
            {
              <Link
                to={nav.shop.path}
                className={selected === nav.shop.path ? "selected" : ""}
              >
                {nav.shop.title}
              </Link>
            }
          </li>
          <li onClick={() => setSelected(nav.blog.path)}>
            {
              <Link
                to={nav.blog.path}
                className={selected === nav.blog.path ? "selected" : ""}
              >
                {nav.blog.title}
              </Link>
            }
          </li>
          <li onClick={() => setSelected(nav.whoami.path)}>
            {
              <Link
                to={nav.whoami.path}
                className={selected === nav.whoami.path ? "selected" : ""}
              >
                {nav.whoami.title}
              </Link>
            }
          </li>
        </div>
      </Nav>
      <HeaderLogo>
        <img src={headerLogo} alt="headerLogo" />
      </HeaderLogo>

      <hr className="fancy-line" />
    </header>
  );
};

interface MobileHeaderProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

interface NavItemProps {
  path: string;
  title: string;
}

export const MobileHeader = ({
  opened,
  setOpened,
  selected,
  setSelected,
}: MobileHeaderProps) => {
  const handleClick = (nav: NavItemProps) => {
    setOpened(!opened);
    setSelected(nav.path);
  };

  return (
    <MobileNav>
      {opened && (
        <HamburgerWrapper>
          <HamburgerMenu>
            <IconActionButton
              icon={<CloseIcon />}
              action={() => setOpened(!opened)}
              className="close-btn"
            />
            <nav>
              <Ul>
                {Object.entries(mobileNav).map((el, idx) => {
                  return (
                    <li onClick={() => handleClick(el[1])} key={idx}>
                      {
                        <Link
                          to={el[1].path}
                          key={idx}
                          className={selected === el[1].path ? "selected" : ""}
                        >
                          {el[1].title}
                        </Link>
                      }
                    </li>
                  );
                })}
              </Ul>
            </nav>
          </HamburgerMenu>
        </HamburgerWrapper>
      )}

      <IconActionButton
        className="menu-icon"
        icon={<MenuIcon />}
        action={() => setOpened(!opened)}
      />
    </MobileNav>
  );
};

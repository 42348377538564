import styled, { css } from "styled-components";

export const ButtonMixing = css`
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: "Montserrat", sans-serif;
  border: transparent;
  line-height: 1.42857143;
  border-radius: 7px;
  font-size: 25px;
  max-width: 402px;
  padding: 8px 40px;
  text-decoration: 1px underline;
  width: 100%;

  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &.m {
    font-size: 20px;
  }

  &.s {
    font-size: 15px;
  }
`;

export const PrimaryButton = styled.button`
  ${ButtonMixing};
  background-color: var(--primary);
  color: white;

  &:hover {
    background-color: var(--primary-bold);
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
`;

export const SecondaryButton = styled.button`
  ${ButtonMixing};
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--primary);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
`;

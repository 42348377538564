import "./loading.css";
interface LoadingProps {
  color?: string;
}

export const Loading = () => (
  <div className="container">
    <svg viewBox="0 0 24 24" xmlns="<http://www.w3.org/2000/svg>">
      <circle
        r="8"
        className="main"
        cx="12"
        cy="12"
        fill="none"
        strokeWidth="2"
        stroke={"tomato"}
      />
    </svg>
  </div>
);

import React, { Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyShopPage = React.lazy(() => import("../pages/ShopPage/ShopPage"));

function ShopPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyShopPage />
    </Suspense>
  );
}

export default ShopPage;

export const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="M3.25 17.625v-1.5h17.5v1.5Zm0-4.875v-1.5h17.5v1.5Zm0-4.875v-1.5h17.5v1.5Z"
        fill="grey"
      />
    </svg>
  );
};

export const headerLogo =
  "https://stellastudio.be/assets/images/header_logo.png";

export const nav = {
  home: { path: "/", title: "Accueil" },
  yoga: { path: "/yoga", title: "Yoga" },
  events: { path: "/events", title: "Events" },
  logo: {
    path: "/",
    title: (
      <img
        style={{ height: "85px", margin: "0 0 32px 0" }}
        alt="logo"
        src={headerLogo}
      />
    ),
  },
  shop: { path: "/shop", title: "Boutique" },
  blog: { path: "/blog", title: "Blog" },
  whoami: {
    path: "/qui-suis-je",
    title: "Qui suis-je",
  },
};

export const mobileNav = {
  home: { path: "/", title: "Home" },
  yoga: { path: "/yoga", title: "Yoga" },
  events: { path: "/events", title: "Events" },
  shop: { path: "/shop", title: "Boutique" },
  blog: { path: "/blog", title: "Blog" },
  whoami: {
    path: "/qui-suis-je",
    title: "Qui suis-je",
  },
};

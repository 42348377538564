import React, { Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyWhoamiPage = React.lazy(
  () => import("../pages/Whoami/Whoami")
);

function WhoamiPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyWhoamiPage />
    </Suspense>
  );
}

export default WhoamiPage;

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./utils/styles/App.css";

import { HeaderMenu, Socials, WrapperApp } from "./utils/styles/GlobalStyles";
import HomePage from "./loadable/HomePage";
import YogaPage from "./loadable/ShoppingCartPage";
import EventsPage from "./loadable/EventsPage";
import ShopPage from "./loadable/ShopPage";
import BlogPage from "./loadable/BlogPage";
import LoginPage from "./loadable/LoginPage";
import WhoamiPage from "./loadable/ContactPage";
import { EnvelopIcon, FacebookIcon, InstagramIcon } from "./assets/svg";
import { IconButton } from "./components/Buttons";
import { useContext, useState } from "react";
import { AppContext } from "./context/AppContext";
import { Header, MobileHeader } from "./components/Header/Header";

function App() {
  const currentPage = window.location.pathname;
  const { email, facebook, instagram } = useContext(AppContext);
  const [selected, setSelected] = useState(currentPage);
  const [opened, setOpened] = useState(false);

  return (
    <div className="App fadeIn">
      <WrapperApp>
        <Router>
          <HeaderMenu>
            <Socials>
              <IconButton link={facebook} icon={<FacebookIcon />} />
              <IconButton link={instagram} icon={<InstagramIcon />} />
              <IconButton link={"mailto:" + email} icon={<EnvelopIcon />} />
            </Socials>
            <MobileHeader
              opened={opened}
              setOpened={setOpened}
              selected={selected}
              setSelected={setSelected}
            />
          </HeaderMenu>
          <Header selected={selected} setSelected={setSelected} />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/yoga" exact component={YogaPage} />
            <Route path="/events" exact component={EventsPage} />

            <Route path="/shop" exact component={ShopPage} />
            <Route path="/blog" exact component={BlogPage} />
            <Route path="/qui-suis-je" exact component={WhoamiPage} />

            <Route path="/login" exact component={LoginPage} />
          </Switch>
        </Router>
      </WrapperApp>
    </div>
  );
}

export default App;

interface IconButtonProps {
  link: string;
  icon: JSX.Element;
}

interface IconActionButtonProps {
  action?: any;
  icon: JSX.Element;
  className?: string;
}

export const IconButton = ({ link, icon }: IconButtonProps) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {icon}
    </a>
  );
};

export const IconActionButton = ({
  action,
  icon,
  className,
}: IconActionButtonProps) => {
  return (
    <a className={className} onClick={action}>
      {icon}
    </a>
  );
};

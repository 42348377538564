interface IconProps {
  color?: string;
}

export const EnvelopIcon = (props: IconProps) => (
  <svg
    width={30.03}
    height={30.03}
    viewBox="0 -4.2 30.03 30.03"
    data-name="15 - Email"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.color ?? "grey"}
      data-name="Path 243"
      d="M30.03 3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v15.622a3 3 0 0 0 3 3h24.03a3 3 0 0 0 3-3V3Zm-2 0v15.622a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h24.03a1 1 0 0 1 1 1Z"
      fillRule="evenodd"
    />
    <path
      data-name="Path 244"
      d="m1.915 3.276 12.613 7.008a1.006 1.006 0 0 0 .972 0l12.615-7.008a1 1 0 1 0-.971-1.748L15.015 8.266 2.887 1.528a1 1 0 1 0-.972 1.748Z"
      fillRule="evenodd"
      fill={props.color ?? "grey"}
    />
  </svg>
);

import React, { Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyEventsPage = React.lazy(
  () => import("../pages/EventsPage/EventsPage")
);

function EventsPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyEventsPage />
    </Suspense>
  );
}

export default EventsPage;

export const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z"
        fill="var(--primary-bold)"
      />
    </svg>
  );
};
